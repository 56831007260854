import React, { useEffect } from 'react';
import { useNotify } from '../contexts/NotifyContext';
import IconCancel from '../assets/icons/IconCancel';
import './MessagePanel.scss';

export default function MessagePanel({close = false}) {
  const { notification, setNotification } = useNotify();

  useEffect(() => {
    if (notification && !close) 
        (setTimeout(() => setNotification(''), 5000));
  });

  return notification ? (
    <div className="MessagePanel">
      <span>
        { notification }
      </span>
      { close &&
        <span className='close-btn' onClick={() => setNotification('')}>
          <IconCancel />
        </span>
      }
    </div>
  ) : <></>
}
